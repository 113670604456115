import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import { SvgIcon, Select, Button } from '@ui-elements';
import { ScheduleHours } from '~/pages/dealer/components/dealer-departments';
import { DealerDetails, Department } from '../models';
import CurrentSchedule from './CurrentSchedule';
import PostCodeField from './PostcodeField';
import styles from '../index.module.scss';

const SelectDealerPanel = (props) => {
  const {
    dealer,
    departmentsFilter,
    handleSelectedDepartment,
    selectedDepartmentOption,
    departments,
    setDealerBySuburb,
    suburb,
    showDealers,
  } = props;

  const [bannerImage, setBannerImage] = useState();
  const [showPostcodeField, setShowPostcodeField] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [postCode, setPostCode] = useState(0);
  const [viewAll, setViewAll] = useState<boolean>();

  const options = [{ id: 1, name: 'All Departments' }, ...departments].map(
    (department: Department) => ({
      label: department.name,
      value: department.name,
    })
  );

  useEffect(() => {
    fetch(`/api/dealers/page-info/${dealer.id}`)
      .then((res) => res.json())
      .then((res) => {
        if (!!res.bannerImages) {
          setBannerImage(res.bannerImages[0]);
          setNotificationMessage(res.notificationMessage);
        }
      })
      .catch((error) => {
        console.log('error getting page info', error);
      });
  }, [dealer]);

  const dealerDetails: DealerDetails[] =
    departmentsFilter.toLowerCase() === 'all departments'
      ? dealer.dealerDetails
      : dealer.dealerDetails.filter(
          (item) => item.departmentName === departmentsFilter
        );

  const selectedDepartment: DealerDetails = dealerDetails[0];
  const phone = selectedDepartment?.phone ?? '';
  const directionsUrl =
    selectedDepartment &&
    `https://www.google.com/maps/dir/Current+Location/${selectedDepartment.address}+${selectedDepartment.postCode}`;

  const DropdownStyles = {
    container: (styles) => ({
      ...styles,
      'div:nth-child(3)': {
        'div:first-child': {
          marginTop: '0px',
        },
      },
    }),
    control: (styles) => ({
      ...styles,
      borderWidth: 0,
      borderBottom: 'none',
      boxShadow: 'none',
      ':hover': {
        borderWidth: 0,
        borderColor: 'transparent',
      },
    }),
    valueContainer: (styles) => ({
      ...styles,
      paddingLeft: 0,
      fontFamily: 'AvenirLT-Medium',
      fontSize: '14px',
      fontWeight: '500',
      letterSpacing: '0',
      lineHeight: '19px',
    }),
    singleValue: (styles) => ({
      ...styles,
      fontFamily: 'AvenirLT-Medium',
      fontWeight: 500,
    }),
  };

  const handleSuburbSelect = (suburbDetail) => {
    setDealerBySuburb(suburbDetail);
    setShowPostcodeField(false);
  };

  const handlePostCodeChange = (value) => {
    setPostCode(value);
  };

  return (
    <div className={styles.PanelContainer}>
      <div
        className={cn(styles.PanelContent, {
          [styles.ExpandHeight]: !!bannerImage,
        })}
      >
        <span className={styles.SelectADealerText}>Select a Retailer</span>
        <div className={styles.ImageContainer}>
          {bannerImage && <img src={bannerImage || ''} />}
        </div>
        <div>
          <span className={styles.DealerName}>{dealer.name}</span>
        </div>
        <div
          className={cn(styles.DealerDetails, {
            [styles.HasMessage]: !!notificationMessage,
          })}
        >
          {selectedDepartment && (
            <>
              {notificationMessage && (
                <div className={styles.DealerDetailInfo}>
                  <div>
                    <SvgIcon
                      type="info"
                      size={1}
                      color="#1637A0"
                      fill="#1637A0"
                    />
                  </div>
                  <div style={{ width: '300px', fontSize: '12px' }}>
                    {notificationMessage}
                  </div>
                </div>
              )}
              <div className={styles.DealerDetail}>
                <div>
                  <SvgIcon
                    type="phone"
                    size={1}
                    color="#1637A0"
                    fill="#1637A0"
                  />
                </div>
                <span className={styles.hideMobile}>{phone}</span>
                <a className={styles.showMobile} href={`tel:${phone}`}>
                  {phone}
                </a>
              </div>
              <div className={styles.DealerDetail}>
                <div>
                  <SvgIcon
                    type="location"
                    size={1.2}
                    color="#1637A0"
                    fill="#ffffff"
                  />
                </div>
                <div>
                  {`${selectedDepartment.address}, ${dealer.state}, ${selectedDepartment.postCode}`}
                  <div>
                    {directionsUrl && (
                      <a href={directionsUrl} target="_blank" rel="noreferrer">
                        Get Directions
                      </a>
                    )}
                  </div>
                </div>
              </div>
              {!!selectedDepartment.url && (
                <div className={styles.DealerDetail}>
                  <div>
                    <SvgIcon
                      type="cursor"
                      size={1}
                      color="#1637A0"
                      fill="#1637A0"
                    />
                  </div>

                  <div className={styles.UnderLined}>
                    <a
                      href={selectedDepartment.url}
                      target="_blank"
                      rel="noreferrer"
                      style={{ color: '#323334', fontWeight: 300 }}
                    >
                      {selectedDepartment.url}
                    </a>
                  </div>
                </div>
              )}
              <div className={styles.DealerDetail}>
                <div>
                  <SvgIcon
                    type="clock"
                    size={1}
                    color="#1637A0"
                    fill="#1637A0"
                  />
                </div>
                <div className={styles.ScheduleContainer}>
                  <div>
                    <CurrentSchedule dealerDetails={selectedDepartment} />
                  </div>
                  <div
                    className={styles.ScheduleViewMore}
                    onClick={() => {
                      setViewAll(!viewAll);
                    }}
                  >
                    {viewAll ? (
                      <SvgIcon
                        type="chevronUp"
                        color="#323334"
                        size="12px"
                        style={{ verticalAlign: 'super', paddingLeft: '10px' }}
                      />
                    ) : (
                      <SvgIcon
                        type="chevronDown"
                        color="#323334"
                        size="12px"
                        style={{ verticalAlign: 'super', paddingLeft: '10px' }}
                      />
                    )}
                  </div>
                </div>
              </div>
              {viewAll && (
                <div className={styles.FullSchedule}>
                  <ScheduleHours
                    label="Mon - Fri"
                    openingHours={selectedDepartment.monFriOpen}
                    closingHours={selectedDepartment.monFriClose}
                  />
                  <ScheduleHours
                    label="Sat"
                    openingHours={selectedDepartment.satOpen}
                    closingHours={selectedDepartment.satClose}
                  />
                  <ScheduleHours
                    label="Sun"
                    openingHours={selectedDepartment.sunOpen}
                    closingHours={selectedDepartment.sunClose}
                  />
                </div>
              )}
              <div className={styles.CTAContainer}>
                <Button
                  className={styles.BookAService}
                  style={{ padding: '10px', width: '100%' }}
                  href={`/dealers/${dealer.uniqueName}`}
                  buttonWidth="full-desktop-only"
                >
                  View Full Details
                </Button>

                {!!dealer && (
                  <div className={styles.ShowAllContainerMobile}>
                    <div>
                      <div
                        className={styles.ShowAllButtonMobile}
                        style={{ padding: '5px', width: '100%', gap: '15px' }}
                        onClick={() => showDealers()}
                      >
                        <SvgIcon
                          type="location"
                          size={1.2}
                          fill="#FFFFFF"
                          color="#1637a0"
                        />
                        Show Surrounding Retailers
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
          <div className={styles.ControlsContainer}>
            <div className={styles.OptionsContainer}>
              <span>Departments</span>
              <Select
                isMulti={false}
                isSearchable={false}
                styles={DropdownStyles}
                value={selectedDepartmentOption}
                options={options}
                menuPlacement="auto"
                menuPosition="fixed"
                openMenuOnClick
                openMenuOnFocus
                onChange={handleSelectedDepartment}
                components={{
                  IndicatorSeparator: () => null,
                }}
                closeMenuOnScroll={(e) => {
                  if (e.target === document) {
                    return true;
                  }
                  return false;
                }}
              />
            </div>
            <div className={styles.Location}>
              {showPostcodeField || !!!suburb ? (
                <PostCodeField
                  handleSuburbSelect={handleSuburbSelect}
                  handlePostCodeChange={handlePostCodeChange}
                  postCode={postCode}
                />
              ) : (
                <div
                  style={{
                    display: 'flex',
                    gap: '10px',
                  }}
                >
                  Location{' '}
                  <span className={styles.Suburb}>
                    {suburb.name}, {suburb.postcode}
                  </span>
                  <div
                    style={{
                      textDecoration: 'underline',
                      cursor: 'pointer',
                    }}
                    onClick={() => setShowPostcodeField(true)}
                  >
                    edit
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectDealerPanel;
