import React, { useState } from 'react';
import { Dropdown } from '~/common/components/ui-elements';
import { SvgIcon } from '@ui-elements';
import { Department } from '../models';
import PostCodeField from './PostcodeField';
import digitalData from '~/common/services/data-layer-service';
import styles from '../index.module.scss';

const SearchDealerPanel = (props) => {
  const {
    departments,
    selectedDepartment,
    handleSelectedDepartment,
    setDealerBySuburb,
    getDealersWithPostcode,
  } = props;

  const [postCode, setPostCode] = useState(0);

  const options = [{ id: 1, name: 'All Departments' }, ...departments].map(
    (department: Department) => {
      return {
        label: department.name,
        value: department.name,
      };
    }
  );

  const handleSuburbSelect = (suburbDetail) => {
    setDealerBySuburb(suburbDetail);
  };

  const handlePostCodeChange = (value) => {
    setPostCode(value);
  };

  const getDealers = () => {
    getDealersWithPostcode(postCode);
  };

  const [showPostcodeField, setShowPostcodeField] = useState(false);

  const handleEnterPostcode = () => {
    setShowPostcodeField(true);
    digitalData.push({
      event: '_formNavigate',
      form: {
        name: 'find a dealer',
        stage: 'enter postcode click',
      },
    });
  };

  const handleShowDealers = () => {
    getDealers();
    digitalData.push({
      event: '_formNavigate',
      form: {
        name: 'find a dealer',
        state: 'show dealers near me',
      },
    });
  };

  return (
    <div className={styles.SearchContainer}>
      <>
        <span className={styles.FindADealerText}>Find a Retailer</span>
        <div>
          <span>I&apos;m looking to find a retailer for</span>
          <Dropdown
            options={options}
            value={selectedDepartment}
            bordered={false}
            onChange={handleSelectedDepartment}
            className={styles.DepartmentDropdown}
          />
        </div>
        <div className={styles.CTAContainer}>
          {showPostcodeField ? (
            <PostCodeField
              handleSuburbSelect={handleSuburbSelect}
              handlePostCodeChange={handlePostCodeChange}
              postCode={postCode}
            />
          ) : (
            <div className={styles.BookAService} onClick={handleEnterPostcode}>
              Enter Postcode
            </div>
          )}
          <div className={styles.AvailService} onClick={handleShowDealers}>
            <SvgIcon
              type="location"
              size={1.2}
              fill="#FFFFFF"
              color="#1637a0"
            />
            <div>Show retailers near me</div>
          </div>
        </div>
      </>
    </div>
  );
};

export default SearchDealerPanel;
